import React, { Component } from "react";

class Error404 extends Component {
  render() {
    return (
      <div style={{ paddingTop: "5%" }}>
        <center>
          <img width="50%" src="https://modugo.com/assets/images/404.png" />
        </center>
      </div>
    );
  }
}

export default Error404;
